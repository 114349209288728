export default {
  hero: {
    background: {
      // image: "/falcon-dark-minus-50-brightness.png",
    }
  },
  color: {
    link: "#4bafbd",
    text: "#f5f5f7",
    background: "linear-gradient(160deg, #101d2d 0%, #203D5C 35%, #2A4B73 45%, #101d2d 90%)",
    fallbackBackground: "#2a2a2a",
  },
  card: {
    // background: "#141414",
    background: "linear-gradient(141.65deg,#0c1621 15.17%,#101d2d 86.5%)",
    // background: "linear-gradient(135deg, #111 0%, #000 100%)",
    boxShadow: "2px 4px 4px rgba(0,0,0,0.45), inset 1px 1px 2px hsla(0,0%,100%,.15)",
    carousel: {
      opacity: 1,
      boxShadow: "-10px -10px 30px rgba(255, 255, 255, 0), 10px 10px 30px rgba(0, 0, 0, 1)"
    },
    mixblendmode: "initial",
    button: {
      text: "#fff",
      background:  "#101d2d",
      boxShadow: {
        primary: "2px 4px 4px rgba(0,0,0,0.45), inset 1px 1px 2px hsla(0,0%,100%,.15)",
        secondary: "2px 4px 4px rgba(0,0,0,0.45), inset 1px 1px 2px hsla(0,0%,100%,.15)",
        hover: "linear-gradient(145deg, rgba(0,0,0,0.66), rgba(255,255,255,0.2))"
      },
      hoverIntensity: 0.25
    }
  },
  screen: {
    width: {
      mobile: 360,
      tablet: 600,
      desktop: 980
    },
    padding: {
      mobile: 20,
      tablet: 20,
      desktop: 48
    }
  }
}
