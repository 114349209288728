export default {
  author: "Carter Roeser",
  hero: {
    subtitle: "Site Reliability Engineer & Software Developer",
    description: ["Site Reliability Engineer at Quickbase. Founder at Rack Manage.", "I love helping people, solving problems, and coming up with unique solutions."],
    cta: [
      {
        title: "Projects",
        link:  "/#projects",
        icon:  "fas fa-arrow-down"
      },
      {
        title: "Resume",
        link:  "/Roeser, Carter Resume.pdf",
        icon:  "fas fa-file-pdf"
      },
      {
        title: "Blog",
        link:  "https://cjroeser.com",
        icon:  "fas fa-pen-nib"
      },
      {
        title: "GitHub",
        link:  "https://github.com/cdgco",
        icon:  "fab fa-github"
      },
      {
        title: "LinkedIn",
        link:  "https://linkedin.com/in/CarterRoeser",
        icon:  "fab fa-linkedin"
      },
    ],
  },
  products: {
    featured: [
      {
        name: "Rack Manage",
        medium: "Vue · Firebase · Cloudflare",
        summary: "Keep your racks in order",
        copy: [
          "Design and manage your datacenter and server racks in a simple and easy way."
        ],
        links: {
          color: "#11B981",
          primary: {
            name: "Get Started",
            url: "https://rackmanage.io/"
          }
        },
        images: [
          "/rackmanage/2.png",
          "/rackmanage/3.png",
          "/rackmanage/1.png",
          "/rackmanage/4.gif",
        ]
      },
      {
        name: "Vesta / Hestia Web Interface",
        medium: "PHP · JavaScript · MySQL",
        summary: "Web Hosting Control Panel",
        copy: [
          "PHP interface for VestaCP / HestiaCP providing a modern, customizable control panel featuring plugins, integrations and branding necessary for any web host."        
        ],
        links: {
          color: "#FF9649",
          primary: {
            name: "Learn more",
            url: "https://hwi.cdgtech.one/"
          }
        },
        images: [
          "/vwi/0.png",
          "/vwi/1.png",
          "/vwi/2.png",
          "/vwi/3.png",
        ]
      },
      {
        name: "01V Web Controller",
        medium: "Express · Electon · WebSockets",
        summary: "Remote Mixer",
        copy: [
          "Multi-Device real time remote controller for Yamaha 01V Mixer. Local server communicates with mixer over MIDI, using web app for wireless control."     
        ],
        links: {
          color: "#598AD7",
          primary: {
            name: "View GitHub",
            url: "https://github.com/cdgco/01v-web-controller"
          }
        },
        images: [
          "/01v/1.jpg",
          "/01v/0.jpg",
          "/01v/2.png",
        ]
      },
      {
        name: "COE Tools",
        medium: "React · PHP · MySQL",
        summary: "IT Tool Management System",
        copy: [
          "Dynamic tool management app with LDAP authenticated roles, drag-and-drop customization, search, sorting, categorization, and embedded frame support."     
        ],
        links: {
          color: "#D63F09",
          primary: {
            name: "View GitHub",
            url: "https://github.com/cdgco/coetools"
          }
        },
        images: [
          "/coetools/0.png",
          "/coetools/3.png",
          "/coetools/1.png",
        ]
      },
      {
        name: "SmartAssets",
        medium: "Vue · Express · MongoDB · ElasticSearch",
        summary: "Modern Asset Management",
        copy: [
          "API first asset management system, built with integration and expansion in mind. Features native barcode support, label printing, a built in barcode scanner, and much more."     
        ],
        links: {
          color: "#1590FF",
          primary: {
            name: "View GitHub",
            url: "https://github.com/cdgco/SmartAssets"
          }
        },
        images: [
          "/smartassets/0.png",
          "/smartassets/1.png",
        ]
      },
      {
        name: "SmartLists",
        medium: "Handlebars · Express · MongoDB",
        summary: "Music Visualization Platform",
        copy: [
          "Music visualization platform allowing users to view the metadata from their Spotify playlists through a range of interactive 2D, 3D, and AI generated graphs and art."     
        ],
        links: {
          color: "#1BD760",
          primary: {
            name: "View GitHub",
            url: "https://github.com/cdgco/smartlists"
          }
        },
        images: [
          "/smartlists/5.png",
          "/smartlists/1.png",
          "/smartlists/4.png",
          "/smartlists/0.gif",
        ]
      },
      {
        name: "Noteworthy",
        medium: "Kotlin · Jetpack Compose · Firebase · GPT-3",
        summary: "AI Assisted Note Taking App",
        copy: [
          "Provides auto completion, summaries, and suggestions for notes, such as searching the web, sending emails, creating reminders and calendar events, or finding directions, with search, categorization, filtering, voice input, and cloud sync."     
        ],
        links: {
          color: "#005591",
          primary: {
            name: "View GitHub",
            url: "https://github.com/cdgco/noteworthy"
          }
        },
        images: [
          "/noteworthy/6.gif"
        ]
      },
      {
        name: "Ray Tracer",
        medium: "C++ · OpenCL · CUDA",
        summary: "Accelerated Ray Tracer Demo",
        copy: [
          "Accelerated ray tracer for comparing 3D rendering speeds for the same environment across different non-accelerated, software accelerated, and hardware accelerated rendering techniques."     
        ],
        links: {
          color: "#7F858F",
          primary: {
            name: "View Project",
            url: "https://raytracing.cjr.app/"
          }
        },
        images: [
          "/raytracer/1.gif",
          "/raytracer/3.png",
          "/raytracer/2.png",
        ]
      },
    ],
    // Only supports 0-3 at the moment
    minor: [
      {
        name: "Dream API",
        description: "API for Wombo Dream AI images. 18k+ Downloads",
        link: {
          name: "Learn More",
          url: "https://dream-api.cjr.app/"
        }
      },
      {
        name: "PHP REST Service",
        description: "Express-Like SPA API Server for PHP",
        link: {
          name: "Learn More",
          url: "https://phprest.cjr.app/"
        }
      },
      {
        name: "Warranty Tool",
        description: "CLI Warranty Lookup Tool for Dell and HP products",
        link: {
          name: "Learn More",
          url: "https://cdgco.github.io/warrantytool/"
        }
      },
    ]
  },
  logoSection: {
    title: "As Hired By",
    // Only supports 0-3 at the moment
    logos: [
      {
        src: "/logos/quickbase.svg",
        url: "https://quickbase.com/",
        alt: "Quickbase"
      },
      {
        src: "/logos/meraki.svg",
        url: "https://meraki.cisco.com/",
        alt: "Cisco Meraki"
      },
      {
        src: "/logos/osu.svg",
        url: "https://engineering.oregonstate.edu/",
        alt: "Oregon State University"
      },
  ]
  },
  footer: {
    // tagline: "Carter Roeser — Corvallis, OR",
    links: [
      {
        name: "Resume",
        url: "/Roeser, Carter Resume.pdf",
        fa: "fas fa-file-pdf"
      },
      {
        name: "Blog",
        url: "https://cjroeser.com",
        fa: "fas fa-pen-nib"
      },
      {
        name: "GitHub",
        url: "https://github.com/cdgco",
        fa: "fab fa-github"
      },
      {
        name: "LinkedIn",
        url: "https://www.linkedin.com/in/CarterRoeser/",
        fa: "fab fa-linkedin"
      },
      {
        name: "Email",
        url: "mailto:carter@cjr.app",
        fa: "fa fa-envelope"
      },
    ]
  }
}
