<template>
    <container>
      <text-container>
        <heading>{{author}}</heading>
        <subtitle>{{subtitle}}</subtitle>
        <paragraph>
          <span v-for="(line, index) in description" :key="index">{{line}}<br/></span>
        </paragraph>
        <cta-section>
          <styled-button v-for="(link, index) in cta" :key="index" secondary :color="'#363636'" style="padding: 0.2rem 1.5rem; padding-top: 0.3rem;" v-bind:href="link.link"><button-text>{{link.title}}</button-text><i :class="link.icon"></i></styled-button>
        </cta-section>
      </text-container>
    </container>
</template>

<script>
import styled from 'vue-styled-components'
import { Subtitle, Paragraph, ButtonText } from './styles/Text.ts'
import { StyledButton } from './Card.vue'

const Container = styled.div`
  max-width: ${({theme}) => theme.screen.width.desktop}px;
  margin: auto;
  /* padding-top: 180px; */

  /* Responsive */
  height: 100vh;
  min-height: 600px;
  max-height: 1035px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TextContainer = styled.div`
  /* TODO: CONFIGURABLE */
  margin: 10px;

  /* Responsive */
  margin-top: -20px;

  @media screen and (max-width: ${({theme}) => theme.screen.width.desktop}px) {
    width: ${({theme}) => theme.screen.width.mobile - theme.screen.padding.mobile * 2}px;
    margin: auto;
  }
`

const Heading = styled.h1`
  font-size: 5rem;
  margin: 32px auto;
  font-weight: 600;
  @media screen and (max-width: ${({theme}) => theme.screen.width.desktop}px) {
    font-size: 72px;
  }
`

const CtaSection = styled.div`
  margin: 36px 0px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
`

export default {
  components: {
    Container,
    TextContainer,
    CtaSection,
    Heading,
    Subtitle,
    Paragraph,
    StyledButton,
    ButtonText
  },
  props: {
    author: String,
    cta: Array,
    subtitle: String,
    description: Array
  }
}
</script>
